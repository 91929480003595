export const ICON_BAR_WIDTH = 60
export const ORION_SIDEBAR_WIDTH = 300
export const REPORTS_SIDEBAR_WIDTH = 256

export const FILE_TYPES = {
  EXCEL_FILE: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
}

export interface ModuleConfig {
  title: string
  description: string
  iconColor: string
  name: string
  to: string
  iconId: string
  activeColor?: string
  exact?: boolean
  featureFlag?: string
  isAdminOnly?: boolean
}
