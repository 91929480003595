import { transparentize } from 'polished'
import {
  DISPLAY_CELL_TYPES,
  EDIT_CELL_TYPES,
  SORT_TYPES,
} from '@pulse-analytics/pulse-design-system/dist/components/DataTable/utils'

import { usePermittedModules, useRewriteSearchString } from 'frontend/hooks'
import { Colors } from 'frontend/utils/pulseStyles'
import { CustomSvgIcon } from 'frontend/components/Icon/CustomSvgIcon'

const { BUTTON } = DISPLAY_CELL_TYPES
const { STRING, DATE } = SORT_TYPES
const {
  TEXT_INPUT,
  SINGLE_SELECT_INPUT,
  CONDENSED_MULTI_SELECT_INPUT,
} = EDIT_CELL_TYPES

export const ROLE_OPTIONS = [
  { value: 'admin', label: 'Admin' },
  { value: 'manager', label: 'Manager' },
  {
    value: 'operator',
    label: 'Operator',
  },
]
export const ORGANIZATION_OPTIONS = [
  { value: 'pulse', label: 'PULSE' },
  { value: 'tdg', label: 'TDG' },
  { value: 'mmit', label: 'MMIT' },
]

const useColConfig = ({
  handleDelete,
  handleUpdate,
  handleUpdateAppMetadata,
}) => {
  const rewriteSearchString = useRewriteSearchString()

  // ! Admins should have access to all options, but need to exclude Admin module itself
  const permittedModules = usePermittedModules()
  const permittedModulesOptions = permittedModules.reduce((acc, module) => {
    const option = {
      value: module.name,
      label: module.title,
    }
    if (module.name !== 'administrator') {
      acc.push(option)
    }
    return acc
  }, [])

  return [
    {
      Header: 'Avatar',
      accessor: 'picture',
      width: 20,
    },
    {
      Header: 'Name',
      accessor: 'name',
      sortType: STRING,
      editCellConfig: {
        type: TEXT_INPUT,
        updateFn: handleUpdate,
      },
    },
    {
      Header: 'Email',
      accessor: 'email',
      sortType: STRING,
    },
    {
      Header: 'Username',
      accessor: 'username',
      sortType: STRING,
    },
    {
      Header: 'Role',
      accessor: 'role',
      sortType: STRING,
      editCellConfig: {
        type: SINGLE_SELECT_INPUT,
        updateFn: handleUpdateUserMetadata,
        options: ROLE_OPTIONS,
      },
    },
    {
      Header: 'Permitted Modules',
      accessor: 'permitted_modules',
      sortType: STRING,
      editCellConfig: {
        type: CONDENSED_MULTI_SELECT_INPUT,
        updateFn: handleUpdateAppMetadata,
        options: permittedModulesOptions,
      },
    },
    {
      Header: 'Organization',
      accessor: 'organization',
      sortType: STRING,
      editCellConfig: {
        type: SINGLE_SELECT_INPUT,
        updateFn: handleUpdateUserMetadata,
        options: ORGANIZATION_OPTIONS,
      },
    },
    {
      Header: 'Last Login Date',
      accessor: 'last_login',
      sortType: DATE,
    },
    {
      Header: 'Edit User',
      accessor: 'x',
      displayCellConfig: {
        type: BUTTON,
        style: {
          background: transparentize(0.9, Colors.PRIMARY),
          borderRadius: 4,
          padding: 2,
          cursor: 'pointer',
        },
        label: (
          <CustomSvgIcon
            iconName="SingleDocument"
            iconColor={Colors.PRIMARY}
            styles={{ width: 20, height: 20 }}
          />
        ),
        onClick: ({ user_id }) => {
          rewriteSearchString({ sidePanel: true, id: user_id })
        },
      },
      width: 50,
      disableSortBy: true,
    },
    {
      Header: 'Delete',
      accessor: 'delete',
      sticky: 'left',
      displayCellConfig: {
        type: BUTTON,
        label: 'Delete',
        onClick: handleDelete,
      },
    },
  ]
}

export default useColConfig
