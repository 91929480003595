import { useFilterConfigFeatureFlags } from 'frontend/hooks'
import { ModuleConfig } from 'frontend/constants'
import { usePolarisUsers } from 'frontend/api/usePolarisUsers'
import { APPLICATION_MODULES } from 'frontend/shared/application-modules'

/**
 * This hook returns an array of permitted modules for the current user,
 * filtered based on feature flags then the current user's permissions.
 * @returns {modules} - An array of module configurations.
 * @example
 * const permittedModules = usePermittedModules();
 * permittedModules will contain the modules that the current user has access to.
 * This can be used to render the sidebar or any other component that requires module access.
 */
export const usePermittedModules = (): ModuleConfig[] => {
  const { currentUser } = usePolarisUsers()
  if (!currentUser?.user_id) return []

  let filteredModules: ModuleConfig[] = useFilterConfigFeatureFlags()(APPLICATION_MODULES)
  filteredModules = filteredModules.reduce<ModuleConfig[]>((acc, config) => {
    const { isAdminOnly, name } = config as ModuleConfig

    if (isAdminOnly && !currentUser?.isAdmin) {
      return acc
    }

    if (!currentUser.getIsPermittedAccess(name.toLowerCase())) {
      return acc
    }

    return [...acc, config]
  }, [])

  return filteredModules
}
