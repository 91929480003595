import { ModuleConfig } from 'frontend/constants'
import Color from 'frontend/utils/color'

export { default } from './PayerPolicyTracker'

export const config: ModuleConfig = {
  title: 'Payer Policy Tracker v1',
  description: 'Track and Manage Payer Policy Links',
  name: 'payer_policy_v1',
  to: '/payer-policy-v1',
  exact: true,
  iconId: 'payertracking-1-white',
  activeColor: Color.PAYER_PROJECTS,
  iconColor: Color.PAYER_PROJECTS,
}
