import React, { Dispatch, SetStateAction } from 'react'
import styled from '@emotion/styled'

import { Email } from './Email'
import { Name } from './Name'
import { Organization } from './Organization'
import { Role } from './Role'
import { Username } from './Username'

interface Props {
  fieldState: any
  setFieldState: Dispatch<SetStateAction<any>>
  handleFieldChange: any
}

const Wrapper = styled.div({
  display: 'flex',
  flexDirection: 'column',
  padding: '12px 12px 12px 12px',
})

const UserForm = ({ fieldState, handleFieldChange }: Props) => {
  return (
    <Wrapper>
      <Username fieldState={fieldState} onChange={handleFieldChange} />
      <Name fieldState={fieldState} onChange={handleFieldChange} />
      <Email fieldState={fieldState} onChange={handleFieldChange} />
      <Role fieldState={fieldState} onChange={handleFieldChange} />
      <Organization fieldState={fieldState} onChange={handleFieldChange} />
    </Wrapper>
  )
}

export default UserForm
