import { ModuleConfig } from 'frontend/constants'
import Color from 'frontend/utils/color'

export { default } from './Reports'

export const config: ModuleConfig = {
  title: 'Reports',
  description: 'Users and Client Tool Access',
  name: 'reports',
  to: '/reports',
  exact: true,
  iconId: 'report',
  activeColor: Color.REPORTS,
  iconColor: Color.REPORTS,
}
