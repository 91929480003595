const USER_COMPLETE =
  'https://res.cloudinary.com/pulsedatatools/image/upload/v1694443590/polaris/icons/user_complete.svg'
const USER_FAILED =
  'https://res.cloudinary.com/pulsedatatools/image/upload/v1694545856/polaris/icons/user_failed.svg'
const USER_PENDING =
  'https://res.cloudinary.com/pulsedatatools/image/upload/v1694443590/polaris/icons/user_pending.svg'

const LOGIN_STATUS_TO_LOGIN_STATUS_ICON_TOOLTIP: {
  [key: string]: { icon: string; toolTip: string }
} = {
  completed: { icon: USER_COMPLETE, toolTip: 'User has logged into Polaris.' },
  delivered: { icon: USER_PENDING, toolTip: 'Sign-up email delivered.' },
  processed: { icon: USER_PENDING, toolTip: 'Sign-up email sent.' },
}

export const getLoginStatus = (loginStatus: string) => {
  const statusType = LOGIN_STATUS_TO_LOGIN_STATUS_ICON_TOOLTIP[loginStatus]
  if (statusType) return statusType
  return { icon: USER_FAILED, toolTip: 'Sign-up email failed to send.' }
}
