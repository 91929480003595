import { ModuleConfig } from 'frontend/constants'
import Color from 'frontend/utils/color'

export { default } from './Delphi'

export const config: ModuleConfig = {
  title: 'Delphi',
  description: 'Email Alert Service',
  name: 'delphi',
  to: '/delphi',
  exact: false,
  iconId: 'mercury-1-white',
  activeColor: Color.DELPHI,
  iconColor: Color.DELPHI,
}
