import { ModuleConfig } from 'frontend/constants'
import Color from 'frontend/utils/color'

export { default } from './PayerProjectsList'

export const config: ModuleConfig = {
  title: 'Payer Projects',
  description: 'Import Payer Historical Data',
  iconColor: Color.PAYER_PROJECTS,
  name: 'payer_projects',
  to: '/payer-projects',
  exact: false,
  iconId: 'payer-1-white',
  activeColor: Color.PAYER_PROJECTS,
}
