import { ModuleConfig } from 'frontend/constants'
import Color from 'frontend/utils/color'

export { default } from './FlatFileExport'

export const config: ModuleConfig = {
  title: 'Flat File Export',
  description: 'Flat File Export',
  name: 'flat_file_export',
  to: '/flat-file-export/list',
  exact: true,
  iconId: 'flat-file-export',
  activeColor: Color.FLAT_FILE_EXPORT,
  iconColor: Color.FLAT_FILE_EXPORT,
}
