
import { config as ClientManagementModule } from 'frontend/ClientManagement'
import { config as OrionModule } from 'frontend/Orion'
import { config as PayerProjectsModule } from 'frontend/PayerProjects/PayerProjectsList'
import { config as PayerPolicyV0Module } from 'frontend/PayerPolicyLinkMgmt/v0'
import { config as DelphiModule } from 'frontend/Delphi'
import { config as FlatFileExportModule } from 'frontend/FlatFileExport'
import { config as ReportModule } from 'frontend/NewReports'
import { config as PayerPolicyV1Module } from 'frontend/PayerPolicyLinkMgmt/v1'
import { config as NcrDataModule } from 'frontend/NcrDataValidator'
import { config as AdministratorModule } from 'frontend/Administrator'

export const APPLICATION_MODULES = [
  ClientManagementModule,
  OrionModule,
  PayerProjectsModule,
  PayerPolicyV0Module,
  DelphiModule,
  FlatFileExportModule,
  ReportModule,
  PayerPolicyV1Module,
  NcrDataModule,
  AdministratorModule,
]
