import React from 'react'
import styled from '@emotion/styled'

import { Spinner } from 'frontend/components'
import Tooltip from 'frontend/components/Tooltip/Tooltip'

import { getLoginStatus } from './getLoginStatus'

const SpinnerWrapper = styled.div({
  marginLeft: 8,
  marginBottom: 5,
})

const LoginStatusIcon = styled.img({
  width: 12,
  height: 12,
  marginLeft: 8,
  marginBottom: 0,
})

const TOOLTIP_PROPS = {
  id: 'pulse-user-login-status-tooltip',
  place: 'top',
  effect: 'solid',
}

const LoginStatus = ({ user }: any) => {
  if (!user)
    return (
      <SpinnerWrapper>
        <Spinner size="10" />
      </SpinnerWrapper>
    )
  const { icon, toolTip } = getLoginStatus(user?.loginStatus)

  return (
    <Tooltip {...TOOLTIP_PROPS} text={toolTip}>
      <LoginStatusIcon src={icon} />
    </Tooltip>
  )
}

export default LoginStatus
