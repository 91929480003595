import { ModuleConfig } from 'frontend/constants'
import Color from 'frontend/utils/color'

export { default } from './Orion'

export const config: ModuleConfig = {
  title: 'Data Management',
  description: 'Import Sheets and Tool Data Management',
  iconColor: Color.ORION,
  name: 'data_management',
  to: '/orion',
  exact: false,
  iconId: 'orion-1-white',
  activeColor: Color.ORION,
}
