import React, { useState, useEffect } from 'react'
import _ from 'lodash'

import { usePolarisUsers } from 'frontend/api/usePolarisUsers'
import { useQueryString, useClearSearchStringParam } from 'frontend/hooks'
import { SlidingPanel } from 'frontend/components'

import Header from './Header'
import UserForm from './UserForm'

export const SidePanel = () => {
  const clearSearchStringParam = useClearSearchStringParam()
  const { id: userId }: { id?: any; sidePanel?: boolean } = useQueryString()
  const { isLoading, data: users } = usePolarisUsers()
  const user = users?.find((user: any) => user.user_id === userId)

  const [fieldState, setFieldState] = useState({})

  const handleFieldChange = ({ name, value }: any) => {
    _.set(fieldState, name, value)
    const newFieldState = {
      ...fieldState,
    }
    setFieldState(newFieldState)
  }

  const handleCancel = () => {
    clearSearchStringParam(['sidePanel', 'id'])
  }

  useEffect(() => {
    if (!isLoading) {
      setFieldState({
        username: user.username,
        name: user.name,
        email: user.email,
        app_metadata: {
          role: user.app_metadata.role,
          organization: user.app_metadata.organization,
          permitted_modules: user.app_metadata.permitted_modules,
        },
      })
    }
  }, [isLoading])

  return (
    <SlidingPanel
      header={<Header user={user} />}
      handleSubmit={() => {}}
      handleCancel={handleCancel}
      isLoading={false}
      disableSubmit={true}
    >
      <UserForm
        fieldState={fieldState}
        setFieldState={setFieldState}
        handleFieldChange={handleFieldChange}
      />
    </SlidingPanel>
  )
}
